import React from 'react'

const initialContext = {
  authenticated: false,
  initialized: false,
  token: {},
  permissions: [],
  userInfo: { username: '', email: '', firstName: '', lastName: '', fullName: '', role: '' },
  logout: async () => { },
  update: () => { },
}
export const AuthContext = React.createContext(initialContext)
