import { Fragment, useCallback, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge, Button, Card, Col, Container, Figure, Modal, Row, Spinner } from 'react-bootstrap';
import apisauce from 'apisauce';
import useAuth from './auth/useAuth'
import * as Icons from 'react-bootstrap-icons'
import { useDropzone } from 'react-dropzone'

const serverURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:33333'

const api = apisauce.create({
  baseURL: serverURL
})

api.axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }
  return config
})


const sizes = [100, 200, 300, 400, 500, 700, 1000]
const Images = ({ dir, images, condensed = false, selected, onSelect }) => {
  const { rawToken } = useAuth()
  const [bigImg, setBigImg] = useState('')
  return <>
    <Modal show={!!bigImg} onHide={() => setBigImg(null)}>
      <Modal.Header closeButton>
        <Modal.Title>{bigImg}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Figure className='me-0'>
          <Figure.Image
            // thumbnail
            // style={{ maxHeight: 80 }}
            alt={bigImg}
            src={serverURL + '/image/' + dir + '/' + bigImg + `?token=Bearer ${rawToken}`}
          />
        </Figure>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setBigImg(null)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    {images.map(imageName =>
      <Col xs={condensed ? 1 : 3} key={dir + '/' + imageName + 'img'} className='my-2'>
        <Card>
          {/* <Card.Img variant="top" src={serverURL + '/image/' + imageName} className='mx-auto mt-2' style={{ height: 100, width: 100 }} /> */}
          <Card.Body className='p-1'>
            {condensed ? (
              <div className='d-flex flex-column justify-content-between align-items-start'>
                <Figure className='me-0'>
                  <Figure.Image
                    // thumbnail
                    style={{ maxHeight: 80 }}
                    role='button'
                    onClick={() => setBigImg(imageName.replace(/\.\d+\.(\w+)$/, '.original.$1'))}
                    alt={imageName}
                    src={serverURL + '/image/' + dir + '/' + imageName + `?token=Bearer ${rawToken}`}
                  />
                </Figure>
                <div className='d-flex flex-row flex-wrap justify-content-center'>
                  {sizes.map(size => {
                    if (condensed)
                      return <div key={dir + imageName + size + 'cthumb'}><small
                        role='button'
                        className={`badge ${selected[dir + '/' + imageName]?.includes(size) ? 'bg-primary text-light' : 'bg-light text-dark'}`}
                        onClick={() => onSelect(imageName, size)} key={imageName + size + 'thumb'}
                      >{size}</small></div>
                    return null
                  })}
                </div>
              </div>)
              :
              <>
                <Card.Title
                  role='button'
                  onClick={() => setBigImg(imageName.replace(/\.\d+\.(\w+)$/, '.original.$1'))}
                >
                  {imageName.replace(/(_.+)?\.\d+\.\w+$/, '')}
                </Card.Title>
                <div className='d-flex flex-wrap justify-content-center align-items-end'>
                  {sizes.map(size => {
                    return <Figure className='me-1' onClick={() => onSelect(imageName, size)} key={dir + imageName + size + 'thumb'}>
                      <Figure.Image
                        thumbnail
                        style={{ maxHeight: size / 7 }}
                        alt={imageName}
                        className={`${selected[dir + '/' + imageName]?.includes(size) ? 'bg-primary' : 'bg-light'} m-0 p-0`}
                        src={serverURL + '/image/' + dir + '/' + imageName + `?token=Bearer ${rawToken}`}
                      />
                      <Figure.Caption>
                        {size}
                      </Figure.Caption>
                    </Figure>
                  })}
                </div>
              </>
            }
          </Card.Body>
        </Card>
      </Col>
    )}
  </>
}

function App() {

  const [data, setData] = useState({})
  const [files, setFiles] = useState([])
  const [condensed, setCondensed] = useState(false)
  const [extended, setExtended] = useState({})

  const [selected, setSelected] = useState({})

  const [loading, setLoading] = useState(false)

  const fetchImages = async () => {
    setLoading(true)
    const { ok, data: d } = await api.get('/images')
    setLoading(false)
    if (ok) {
      setData(d)
      setSelected({})
    }
  }

  const downloadSelection = useCallback(async (flat = false) => {

    const selectedFiles = Object.entries(selected).reduce((acc, [image, selectedSizes]) => {
      selectedSizes.forEach(s => acc.push(image.replace('.100.', `.${s}.`)))
      return acc
    }, [])
    console.log(selectedFiles)
    setLoading(true)
    const { ok, data: d } = await api.post('download', { files: selectedFiles, flat }, {
      responseType: 'arraybuffer',
    })
    setLoading(false)
    if (ok) {
      const blob = new Blob([d], { type: 'octet/stream' })
      const anchor = document.createElement('a')
      if (anchor) {
        const url = URL.createObjectURL(blob)
        anchor.href = url
        anchor.download = `selection${flat ? '-flat' : ''}.zip`
        anchor.click()
        URL.revokeObjectURL(url)
      }
    }
  }, [selected])

  useEffect(() => {
    fetchImages()
  }, [])

  const handleDrop = droppedFiles => {
    setFiles(droppedFiles)
  }

  const upload = async dir => {
    const form = new FormData()
    for (const file of files) {
      form.append('photos', file)
    }
    setLoading(true)
    const { ok } = await api.post('/images/' + dir, form)
    setLoading(false)
    setFiles([])
    if (ok)
      await fetchImages()
    else alert('Upload error')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop })

  const [uploadDir, setUploadDir] = useState('Misc')

  const [showUpload, setShowUpload] = useState(false)

  return (
    <Container style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 5, top: -10 }} onClick={() => setShowUpload(prev => !prev)}>
        {showUpload ? <Icons.DashCircle /> : <Icons.PlusCircle />}
      </div>
      {/* <pre>{JSON.stringify(selected, null, 2)}</pre> */}
      {showUpload && <Row className='my-4'>
        <Col xs={12}>
          <div
            {...getRootProps()}
            style={{ minHeight: 100 }}
            className={`p-3 rounded rounded-5 border border-1 d-flex justify-content-center align-items-start flex-column ${isDragActive ? 'bg-warning' : 'bg-light'}`}
          >
            <input {...getInputProps()} />
            {!files.length && 'Drag files here to upload / click to open file dialog'}
            {files.map(file => <div key={file.name}>{file.name} - {(file.size / 1000).toFixed(2)}KB</div>)}
          </div>
        </Col>
        <Col>
          <Button onClick={() => upload(uploadDir)} variant='success' className='mt-2' disabled={!files.length || loading}>Upload</Button>
          <select className='select' value={uploadDir} onChange={e => setUploadDir(e.target.value)}>
            {Object.keys(data).map(dir => <option key={dir + 'upload'}>{dir}</option>)}
          </select>
        </Col>
      </Row>}
      <Row className='my-4'>
        <Col xs={12}>
          <div>Selected: {Object.entries(selected).reduce((acc, [img, sizes]) => acc + sizes.length, 0)}</div>
          <Button variant='dark' disabled={!Object.keys(selected).length || loading} onClick={() => downloadSelection()}>Download Selection</Button>
          <Button variant='dark' className='ms-2' disabled={!Object.keys(selected).length || loading} onClick={() => downloadSelection(true)}>Download Selection Flat</Button>
        </Col>
      </Row>
      <Row>
        <Col className='my-2'>
          {loading ? <Spinner animation='border'></Spinner> : ''}
        </Col>
      </Row>
      <Row className='my-4'>
        <Col xs={12}>
          <h4>
            {sizes.map(size => (
              <Fragment key={size + 'frag'}>
                <Badge pill
                  bg='success'
                  text='light'
                  className='me-1 font-20'
                  role='button'
                  onClick={() => {
                    setSelected(prev => {
                      const updated = {}
                      for (const [dir, images] of Object.entries(data)) {
                        for (const file of images) {
                          const curr = prev[dir + '/' + file]
                          updated[dir + '/' + file] = curr ? curr.includes(size) ? curr : [...curr, size] : [size]
                        }
                      }
                      return updated
                    })
                  }}>
                  {size}
                </Badge>
                <Badge pill
                  bg='danger'
                  text='light'
                  className='me-3 font-20'
                  role='button'
                  onClick={() => {
                    setSelected(prev => {
                      const updated = {}
                      for (const [dir, images] of Object.entries(data)) {
                        for (const file of images) {
                          const curr = prev[dir + file]
                          const filtered = curr ? (curr.includes(size) ? curr.filter(s => s !== size) : curr) : []
                          if (filtered.length) updated[dir + '/' + file] = filtered
                        }
                      }
                      return updated
                    })
                  }}>
                  <span style={{ textDecoration: 'line-through', textDecorationThickness: 2 }}>{size}</span>
                </Badge>
              </Fragment>
            ))}
            <Badge pill
              bg='secondary'
              text='light'
              className='me-2 font-20'
              role='button'
              onClick={() => {
                const updated = []
                for (const [dir, images] of Object.entries(data)) {
                  for (const file of images) {
                    updated[dir + '/' + file] = [...sizes]
                  }
                }
                setSelected(updated)
              }}>
              all
            </Badge>
            <Badge pill
              bg='secondary'
              text='light'
              className='me-2 font-20'
              role='button'
              onClick={() => setSelected([])}>
              none
            </Badge>
            <Badge pill
              bg='warning'
              text='light'
              className='me-2 font-20'
              role='button'
              onClick={() => setCondensed(prev => !prev)}>
              {condensed ? 'Relaxed' : 'Condensed'}
            </Badge>
          </h4>
        </Col>
      </Row>
      {Object.entries(data).map(([dir, images]) => {
        return (
          <Row key={dir + '-dir'}>
            <div className='col-12'>
              <h3 role='button' onClick={() => setExtended(prev => ({ ...prev, [dir]: !prev[dir] }))} className='d-flex align-items-center bg-info ps-2 py-2'>
                {extended[dir] ? <Icons.DashSquare /> : <Icons.PlusSquare />}
                <div className='ms-2'>{dir} ({images.length})</div>
              </h3>
            </div>
            {extended[dir] && <Images dir={dir} images={images} colsize={12} condensed={condensed} selected={selected} onSelect={(image, size) => setSelected(prev => {
              const current = prev[dir + '/' + image]
              if (!current) return { ...prev, [dir + '/' + image]: [size] }
              if (current.includes(size))
                return { ...prev, [dir + '/' + image]: current.filter(s => s !== size) }
              return { ...prev, [dir + '/' + image]: [...current, size] }
            })} />}
          </Row>
        )
      })
      }
    </Container >
  );
}

const AuthApp = () => {
  const { initialized, authenticated, rawToken } = useAuth()

  useEffect(() => {
    const storageToken = localStorage.getItem('token')
    console.log('Updating token')
    if (!storageToken || storageToken !== rawToken) {
      localStorage.setItem('token', rawToken)
    }
  }, [initialized, authenticated, rawToken])

  return !initialized ? 'Initializing' : (
    !authenticated ? 'Not authenticated' :
      <App />
  )
}

export default AuthApp;
