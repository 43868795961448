import React from 'react'
import { AuthContext } from './AuthContext'

const useAuth = () => {
    const {
        token,
        rawToken,
        authenticated,
        initialized,
        permissions,
        userInfo,
        logout,
    } = React.useContext(AuthContext)

    return Object.assign([
        token,
        rawToken,
        authenticated,
        initialized,
        permissions,
        userInfo,
        logout,
    ], {
        token,
        rawToken,
        authenticated,
        initialized,
        permissions,
        userInfo,
        logout,
    })
}
export default useAuth