import React, { useState } from 'react'
import * as Keycloak from 'keycloak-js'
import { ReactKeycloakProvider as KeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { AuthContext } from './AuthContext'

const {
  REACT_APP_KEYCLOAK_URL: url,
  REACT_APP_KEYCLOAK_REALM: realm,
  REACT_APP_KEYCLOAK_CLIENT_ID: clientId,
  REACT_APP_KEYCLOAK_IDP_ALIAS: idpAlias
} = process.env

console.log({ url, realm, clientId, idpAlias })

const keycloakInstance = new Keycloak({
  url,
  realm,
  clientId,
})

let kcLogin = keycloakInstance.login
keycloakInstance.login = (options) => {
  if (options) options.idpHint = idpAlias
  else options = { idpHint: idpAlias }
  kcLogin(options)
}

const SubWrap = ({ updatedToken, ...props }) => {

  const { keycloak, initialized } = useKeycloak()

  const authenticated = initialized && keycloak.authenticated
  const token = initialized && authenticated ? keycloak.tokenParsed : null

  const userInfo = authenticated ? {
    username: token.preferred_username,
    fullName: token.name,
    email: token.email || '',
    firstName: token.given_name,
    lastName: token.family_name,
    role: token.role,
  } : { username: '', email: '', firstName: '', lastName: '', fullName: '', role: '' }
  const permissonsRaw = token ? token.permissions : []
  const permissions = authenticated ? (typeof permissonsRaw === 'string' ? permissonsRaw.split(',') : permissonsRaw) : []

  return (
    <AuthContext.Provider value={{
      authenticated,
      initialized,
      token: updatedToken,
      rawToken: keycloak.token,
      permissions,
      userInfo,
      logout: () => {
        keycloak.logout()
      },
    }} {...props}>
    </AuthContext.Provider>
  )
}

const AuthWrap = ({ component: Component }) => {

  const [token, setToken] = useState()

  return (
    <KeycloakProvider
      authClient={keycloakInstance}
      initOptions={{
        useNonce: true,
        onLoad: 'login-required',
        // onLoad: 'check-sso',
        autoRefreshToken: true,
        enableLogging: true,
        // flow: 'implicit',
        // responseMode: 'fragment',
        // responseType: 'id_token token',
      }}
      onError={error => console.error('error', error)}

      onEvent={event => {
        if (event === 'onAuthSuccess') {
          console.log('Auth Success')
        } else if (event === 'onAuthLogout') {
          console.log('Logout Ok')
        } else {
          console.log('event', event)
        }
      }}
      onTokens={tokens => {
        console.log('Tokens')
        setToken(tokens.token)
      }}
    >
      <SubWrap updatedToken={token}>
        <Component />
      </SubWrap>
    </KeycloakProvider>
  )
}

export default AuthWrap
